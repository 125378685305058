import { QueryCache, QueryClient } from "react-query";

export const queryCache = new QueryCache({
  onError: (error) => {},
  onSuccess: (data) => {},
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
