/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Divider, Input, Modal, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { getSubscriptionPackage } from "../../SubscriptionPackage/utils/getSubscriptionPackages";
import { AddOn, AddOnPayload } from "./utils/interface";
import { postAddOnChanges, putAddOnChanges } from "./utils/upateAddOn";

type Props = {
  pharmacyId: number | string;
  selectedAddOn: AddOn | null;
  selectedAddOonList: AddOn[];
  onCloseModal: () => void;
  refetch: () => void;
};

export const PharmacyAddOnForm = ({ onCloseModal, selectedAddOn, pharmacyId, selectedAddOonList, refetch }: Props) => {
  const [formData, setFormData] = useState<{
    activeDate: null | Moment;
    addOnId: null | number | string;
    reason: string;
    description: string;
  }>({ activeDate: null, addOnId: null, reason: "", description: "" });
  const [error, setError] = useState({
    activeDate: "",
    addOnId: "",
    reason: "",
    description: "",
  });
  const [loadingPackageList, setLoadingPackageList] = useState(false);
  const [addOnListOption, setAddOnListOption] = useState<AddOn[]>([]);

  const handleChangeDate = (date: any) => {
    setFormData({ ...formData, activeDate: date });
  };
  const handleChangeAddOn = (value: string) => {
    setFormData({ ...formData, addOnId: value });
  };

  const fetchAddOnListOptions = async () => {
    if (selectedAddOn) {
      setAddOnListOption([selectedAddOn]);
      return;
    }

    setLoadingPackageList(true);
    const activeAddOns: { [key: number]: boolean } = {};

    selectedAddOonList.forEach((a) => {
      activeAddOns[a.id] = true;
    });

    const { packageList } = await getSubscriptionPackage();
    const filtered = (packageList || []).filter((p: any) => {
      return p.package_type === "addons" && p.is_active && !activeAddOns[p.id];
    });

    const transformAddOnFormat: AddOn[] = (filtered || []).map((f: any) => {
      return {
        id: f.id,
        name: f.title,
        description: f.description || "",
        active_until: "",
      };
    });

    setAddOnListOption(transformAddOnFormat);
    setLoadingPackageList(false);
  };

  const options = useMemo(() => {
    return addOnListOption.map((a: AddOn, index: number) => {
      return (
        <Select.Option value={a.id + ""} key={`option-${index}`}>
          {a.name || ""}
        </Select.Option>
      );
    });
  }, [addOnListOption]);

  useEffect(() => {
    fetchAddOnListOptions();

    if (selectedAddOn) {
      setFormData({
        activeDate: moment(selectedAddOn.active_until || null),
        addOnId: selectedAddOn.id,
        reason: "",
        description: "",
      });
    }
  }, []);

  const validation = () => {
    let isValid = true;
    let tempError = {
      activeDate: "",
      addOnId: "",
      reason: "",
      description: "",
    };

    if (!formData.addOnId) {
      tempError.addOnId = "Paket add on  harus dipilih.";
      isValid = false;
    }

    if (!formData.activeDate) {
      tempError.activeDate = "Tanggal harus diisi.";
      isValid = false;
    }

    if (formData.activeDate && moment(formData.activeDate).isBefore(moment())) {
      tempError.activeDate = "Tanggal tidak boleh kurang dari hari ini.";
      isValid = false;
    }

    if (!formData.reason) {
      tempError.reason = "Alasan harus diisi.";
      isValid = false;
    }

    if (!formData.description) {
      tempError.description = "Deskripsi alasan harus diisi.";
      isValid = false;
    }

    return { ...tempError, isValid };
  };

  const onSave = async () => {
    const payload: AddOnPayload = {
      addon_id: formData.addOnId,
      active_until: moment(formData.activeDate).format("YYYY-MM-DD"),
      reason: formData.reason || "",
      description: formData.description || "",
    };

    const { isValid, ...tempError } = validation();

    if (!isValid) {
      setError({ ...tempError });
      return;
    }

    setLoadingPackageList(true);
    if (selectedAddOn) {
      await putAddOnChanges(pharmacyId, selectedAddOn.id, payload);
      refetch();
      onCloseModal();
      return;
    }

    await postAddOnChanges(pharmacyId, payload);
    setLoadingPackageList(false);
    refetch();
    onCloseModal();
  };

  return (
    <Modal
      visible={true}
      title={selectedAddOn ? "Ubah masa aktif add on" : "Tambah add on"}
      onOk={() => onSave()}
      onCancel={onCloseModal}
      confirmLoading={loadingPackageList}
    >
      <Row>
        <div>
          <h3>Add on</h3>
          <Select
            disabled={!!selectedAddOn || loadingPackageList}
            value={formData.addOnId?.toString() || ""}
            style={{
              width: 180,
              borderColor: error.addOnId ? "red" : undefined,
            }}
            loading={loadingPackageList}
            onChange={handleChangeAddOn}
          >
            {options}
          </Select>
          {error.addOnId && <h5 style={{ color: "red" }}>{error.addOnId}</h5>}
        </div>
      </Row>

      <Divider />

      <div>
        <h3>Aktif sampai dengan</h3>
        <DatePicker
          style={{ borderColor: error.activeDate ? "red" : undefined }}
          format='YYYY-MM-DD'
          onChange={handleChangeDate}
          value={formData.activeDate}
        />
        {error.activeDate && <h5 style={{ color: "red" }}>{error.activeDate}</h5>}
      </div>

      <Divider />

      <div>
        <h3>Alasan perubahan</h3>
        <Input
          style={{ borderColor: error.reason ? "red" : undefined }}
          onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
          value={formData.reason}
        />
        {error.reason && <h5 style={{ color: "red" }}>{error.reason}</h5>}

        <h3 style={{ marginTop: "16px" }}>Deskripsi perubahan</h3>
        <TextArea
          style={{ borderColor: error.description ? "red" : undefined }}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          value={formData.description}
        />
        {error.description && <h5 style={{ color: "red" }}>{error.description}</h5>}
      </div>
    </Modal>
  );
};

export default PharmacyAddOnForm;
