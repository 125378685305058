/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import axios from "../../config/axios";

// component
import { Layout, Form, Input, Button, notification, Table, message, Modal, Upload, Row, Col } from "antd";
import debounce from "lodash/debounce";
import { Content, Header } from "antd/lib/layout/layout";
import { InboxOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import Sidebar from "../../component/Sidebar";

// constant
import { BASE_URL_BETA } from "../../constant/api";

const FarmacareCatalog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [form] = Form.useForm();
  const [selectedProduct, setSelectedProduct] = useState<ProductCatalogList | null>(null);
  const [productList, setProductList] = useState<ProductCatalogList[]>([]);
  const [showModalDeleteProduct, setShowModalDeleteProduct] = useState(false);
  const { Search } = Input;
  const [search, setSearch] = useState("");

  const handleSetDeleteProduct = (data: ProductCatalogList) => {
    setShowModalDeleteProduct(true);
    setSelectedProduct(data);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  interface ProductCatalogList {
    registration_number: string;
    old_name: string;
    product_display_name: string;
    package: string;
    catalog_id: string;
  }

  const getProductList = async () => {
    try {
      setIsLoading(true);
      const url = `${BASE_URL_BETA}/upload-catalog`;

      const response = await axios.get(url, { params: { search: search } });
      if (response.status === 200) {
        let { product } = response.data;
        product.map((e: any) => {
          e.product_display_name = e.product_display_name ? e.product_display_name : "Belum ada data verifikasi";
          e.package = e.package ? e.package : "Belum ada data verifikasi";
        });
        setProductList(response.data.product);
      }
      setPagination({
        ...pagination,
        total: response.data.total_data,
      });
    } catch (err) {
      notification.error({ message: "Gagal memuat" });
    } finally {
      setIsLoading(false);
    }

    // setProductList([
    //   {
    //     registration_number: "22222",
    //     product_display_name: "ssssss",
    //     package: "SJKSJSSJ",
    //   },
    // ]);
  };

  const handleTableChange = async (pagination: any) => {
    try {
      setIsLoading(true);
      const url = `${BASE_URL_BETA}/upload-catalog`;

      const response = await axios.get(url, { params: { search: search } });
      setProductList(response.data.product);
      setPagination({
        ...pagination,
        total: response.data.total_data,
      });
      setIsLoading(false);
    } catch (err) {}
  };

  const handleDeleteProduct = async () => {
    try {
      setIsLoading(true);

      const { catalog_id } = selectedProduct!;

      const response = await axios.delete(`${BASE_URL_BETA}/upload-catalog/${catalog_id}`);
      if (response.status === 200) {
        notification.success({ message: "Berhasil menghapus" });
        setShowModalDeleteProduct(false);
      }
    } catch (err) {
      notification.error({ message: "Gagal menghapus baran" });
    } finally {
      setIsLoading(false);
      getProductList();
    }
  };

  const handleUploadFile = async () => {
    try {
      setIsLoading(true);

      const dataFile = new FormData();
      dataFile.append("file", selectedFile);

      const response = await axios.post(`${BASE_URL_BETA}/upload-catalog`, dataFile);

      if (response.status === 200) {
        notification.success({ message: "Berhasil memperbaharui" });
        setShowModalConfirmation(false);
        form.resetFields();
      }
    } catch (err) {
      notification.error({ message: "Gagal memperbaharui" });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!selectedFile) {
      message.error("file tidak boleh kosong");
      return;
    }

    setShowModalConfirmation(true);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    setSelectedFile(e.file.originFileObj);
  };

  const formItemLayout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 100,
    },
  };

  const columns = [
    {
      title: "Nomor Registrasi",
      dataIndex: "registration_number",
      key: "registration_number",
    },
    {
      title: "Nama Lama",
      dataIndex: "old_name",
      key: "old_name",
    },
    {
      title: "Nama Baru",
      dataIndex: "product_display_name",
      key: "product_display_name",
    },
    {
      title: "Kemasan",
      dataIndex: "package",
      key: "package",
    },
    {
      title: "Aksi",
      dataIndex: "catalog_id",
      key: "catalog_id",
      render: (val: any, data: ProductCatalogList) => {
        return (
          <Row>
            <Col>
              <Button onClick={() => handleSetDeleteProduct(data)}>Hapus</Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const onSearch = debounce((text: string) => {
    setSearch(text);
  }, 800);

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModalDeleteProduct(false);
  };

  useEffect(() => {
    if (search !== "") {
      getProductList();
    }
  }, [search]);

  return (
    <>
      <Modal visible={showModalDeleteProduct} onCancel={handleCloseModal} onOk={handleDeleteProduct} confirmLoading={isLoading}>
        <Row>
          <Col>
            <h2>Hapus barang!</h2>
            <Row>
              <p>Apakah kamu yakin ingin menghapus barang ini?</p>
            </Row>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={showModalConfirmation}
        title='Perhatian'
        onCancel={() => setShowModalConfirmation(false)}
        onOk={() => handleUploadFile()}
      >
        Apakah kamu yakin ingin mengunggah file ini ?
      </Modal>

      <Layout style={{ height: "100vh" }} {...formItemLayout}>
        <Sider breakpoint='lg' collapsedWidth='0'>
          <Sidebar activeTab={"fc-catalog"} />
        </Sider>
        <Layout>
          <Header style={{ background: "#fff" }}>
            <h1>Farmacare Catalog</h1>
          </Header>
          <Content
            style={{
              padding: "24px",
              paddingBottom: "100px",
              overflowY: "scroll",
            }}
          >
            <Button
              onClick={() =>
                window.open("https://farma-dev.s3.ap-southeast-1.amazonaws.com/template_farmacare_catalog.csv", "_self")
              }
            >
              Download template{" "}
            </Button>
            <Form style={{ marginTop: "25px" }} {...formItemLayout} layout='vertical' form={form} onFinish={onSubmit}>
              <Form.Item label='Unggah file CSV'>
                <Form.Item name='dragger' valuePropName='fileList' getValueFromEvent={normFile} noStyle>
                  <Upload.Dragger name='files' action='/upload.do'>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-hint'>Klik atau seret file ke area ini untuk mengunggah</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  //   loading={isLoading}
                  htmlType='submit'
                  style={{ marginTop: "15px" }}
                >
                  Unggah
                </Button>
              </Form.Item>
            </Form>

            <Row style={{ marginBottom: "16px", marginTop: "50px" }}>
              <Search placeholder='ketik nomor registrasi' onSearch={onSearch} style={{ width: 200 }} />
            </Row>
            <Table
              dataSource={productList}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
            />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default FarmacareCatalog;
