import { AxiosResponse } from "axios";
import { useInfiniteQuery, useQuery } from "react-query";

import { Pharmacy } from "../../types/pharmacy";
import { OrgTypeFilter, PaginationFilter } from "./interface";
import { PharmacyService } from "./pharmacyService";

export type WorkspaceFilter = { is_hidden?: boolean };

export const useGetPharmacyList = (
  filter: PaginationFilter & OrgTypeFilter & WorkspaceFilter,
  enabled: boolean,
  onError?: (err: any) => void
) => {
  const {
    data = { pharmacies: [], totalData: 0 },
    status,
    isFetching,
    error,
    isRefetching,
    refetch,
  } = useQuery(["pharmacyList", filter], async () => PharmacyService.getPharmacyList(filter), {
    select: (response: AxiosResponse) => {
      return {
        pharmacies: response.data?.company || [],
        totalData: response.data?.metadata?.total_data || 0,
      };
    },
    enabled,
    onError: (err: any) => onError && onError(err),
  });

  return {
    list: data.pharmacies as Pharmacy[],
    totalData: data.totalData,

    error: error as any,
    status,
    isFetching,
    isRefetching,
    refetch,
  };
};

export const useGetInfinitePharmacyList = ({
  enabled,
  filter,
  onError,
  onSuccess,
}: {
  filter: PaginationFilter & OrgTypeFilter & WorkspaceFilter;
  enabled: boolean;
  onError?: (err: any) => void;
  onSuccess?: () => void;
}) => {
  const { data, isFetching, refetch, fetchNextPage } = useInfiniteQuery(
    ["pharmacyList", filter],
    async ({ pageParam }) => PharmacyService.getPharmacyList({ ...filter, current: pageParam }),
    {
      enabled,
      onError: (err: any) => onError && onError(err),
      onSuccess,
    }
  );

  const list = (data?.pages || []).reduce((list: Pharmacy[], e) => {
    return [...list, ...(e.data.company || [])];
  }, []);

  const pages = (data?.pageParams || []) as number[];
  const lastPage: number = pages?.[pages?.length - 1] || 1;
  const totalData = data?.pages?.[0]?.data?.metadata?.total_data || 0;
  const isHasNextData = totalData > list.length;

  return {
    list: list as Pharmacy[],
    totalData: totalData,
    isHasNextData,
    isFetching,
    refetch,
    onFetchNextPage: () => {
      fetchNextPage({ pageParam: lastPage + 1 });
    },
  };
};
